$spacing: (
  "sm": 8px,
  "md": 16px,
  "lg": 24px,
);

$breakpoints: (
  "sm": 600px,
  "md": 960px,
  "lg": 1280px,
);

$justifyContent : (center, space-between, space-around, space-evenly, flex-start, flex-end);

$alignItems : (center, flex-start, flex-end);

$textAlign : (center, start, end);

$flexDirection : (row, column);


@mixin breakpoint($name) {
  @media screen and (min-width: map-get($breakpoints, $name)) {
    @content;
  }
}

.grid {
  &-container {
    display: flex;
    flex-wrap: wrap;
}


  @each $value in $textAlign{
    &-textAlign-#{$value}{
      text-align: $value;
    }
  }

  @each $value in $flexDirection{
    &-flexDirection-#{$value}{
      flex-direction: $value;
    }
  }

  @for $i from 1 to 13 {
    &-gap-#{$i} {
      gap: #{$i}rem;
    }
  }

  &-item {
    display: block;
  }

  @for $i from 1 to 13 {
    &-cols-#{$i} {
      flex-basis: $i/12 * 100%;
    }
  }

  @for $i from 1 to 13 {
    &-xs-#{$i} {
      flex-basis: $i/12 * 100%;
    }
  }

  @include breakpoint("sm") {
    @for $i from 1 to 13 {
      &-sm-#{$i} {
        flex-basis: $i/12 * 100%;
      }
    }
  }
  @include breakpoint("md") {
    @for $i from 1 to 13 {
      &-md-#{$i} {
        flex-basis: $i/12 * 100%;
      }
    }
  }
  @include breakpoint("lg") {
    @for $i from 1 to 13 {
      &-lg-#{$i} {
        flex-basis: $i/12 * 100%;
      }
    }
  }

  @each $key, $value in $spacing {
    &-spacing-#{$key} {
      margin: -$value;
    }
    
    &-spacing-#{$key} > &-item {
      padding: $value;
    }
  }

  @each $value in $justifyContent{
    &-justifyContent-#{$value}{
        justify-content: $value;
    }
  }

  @each $value in $alignItems{
    &-alignItems-#{$value}{
        align-items: $value;
    }
  }
}
