.text-area {
    width: 100%;
    max-width: calc(100% - 4rem);
    resize: none;
    font-size: var(--font-size-14);
    font-family: var(--default-font-family);
    color: var(--grey-color);
    padding: 0 0.5rem;
    transition: 0.3s ease-in;
    outline: none;
    background: var(--pure-white-color);
  }
  
  // outlined input field
  .text-area-outlined {
    border: 1px solid var(--bluish-gray-color);
    border-radius: 6px;
  
    &:focus {
      outline: none;
      border: 1px solid var(--primary-color);
    }
  }
  
  // input field when error
  .text-area-error {
    border: 1px solid var(--red-color);
    border-radius: 6px;
  }
  .text-area-disabled{
    background: var(--light-grayish-blue-color);
  }