.pagination-container{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.pagination {
  display: flex;
  gap: 0.5rem;
}

.pagination-item {
  background: var(--primary-color);
  border-radius: 2px;
  border: 1px solid var(--bluish-gray-color);
  background: var(--pure-white-color);
  width: 30px;
  aspect-ratio: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  list-style: none;
  transition: transform 0.3s;
}

.pagination-item:hover{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
    border: none;
    transform: scaleX(1.1);
}

.active-page{
    background: var(--primary-color);
}

.active-page .pagination-link{
    color: var(--pure-white-color);
}


.button-disabled{
    pointer-events: none;
    cursor: not-allowed;
}

.pagination-link, .listing {
  color: var(--grey-color);
  text-align: center;
  font-family: Lato;
  font-size: var(--font-size-14);
  font-style: normal;
  font-weight: var(--font-weight-500)
}


.listing-select{
  border-radius: 2px;
border: 1px solid var(--bluish-gray-color);
color: var(--grey-color);
outline: none;
cursor: pointer;
}



.pagination-listing-container{
  display: flex;
  gap: 0.3rem;
}