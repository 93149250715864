.loader-container{
  background-color: var(--pure-white-color);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  inset: 0;
  margin: auto;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.bouncing-loader {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

.bouncing-loader > div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.5;
    transform: translateY(-8px);
  }
  0% {
    background-color: #494848;
  }
  33% {
    background-color: #636363;
  }
  50% {
    background-color: #909090;
  }
  75% {
    background-color: #b4b4b4;
  }
  100% {
    background-color: #d4d4d4;
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}
.bouncing-loader > div:nth-child(4) {
  animation-delay: 0.6s;
}
.bouncing-loader > div:nth-child(5) {
  animation-delay: 0.8s;
}
