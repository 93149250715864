.bread-crumb-container {
  .bread-crumb-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    color: var(--grey-color);
    text-transform: capitalize;
  }
  .bread-crumb-main-text{
    color: var(--jet-black-color);
    font-weight: 700;
  }

}
