.label {
  color: var(--midnight-blue-color);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16);
  font-family: var(--font-family-lato);
}

.label-container{
  margin-bottom: 0.8rem;

  .label-required{
    color:var(--red-color)
  }
}