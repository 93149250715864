.main-layout-container {
  display: flex;
  flex-direction: row;

  .idebar-layout-container {
    position: relative;
  }

  .content-wrapper {
    width: 100%;
    padding: 1rem 1rem 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
