.checkbox-container {
  gap: 0.3rem;

  .checkbox-title {
    text-transform: lowercase;
  }
  .checkbox-title::first-letter {
    text-transform: uppercase;
  }
}

.checkbox {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--pure-white-color);
  margin: 0;
  width: 1.15rem;
  height: 1.15rem;
  border: 0.15rem solid var(--bluish-gray-color);
  border-radius: 0.15rem;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  cursor: pointer;
}

.checkbox::before {
  content: "";
  width: 0.65rem;
  height: 0.65rem;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--primary-color);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.checkbox:checked {
  border: 0.15rem solid var(--primary-color);
}
.checkbox:checked::before {
  transform: scale(1);
}
