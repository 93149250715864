.page-not-found-container {
  min-height: 100vh;

  .heading {
    color: var(--primary-color);
    font-family: var(--default-font-family);
    font-size: 124px;
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-800);
    line-height: 85.591px;
  }

  .sorry-page-not-found {
    color: var(--eerie-black-color);
    font-family: var(--default-font-family);
    font-size: 22px;
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-500);
    line-height: 42.795px;
  }
}
