.tabs-wrapper {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--light-blue-color);
  height: 2.219rem;
}

.tabs-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  .tab {
    outline: none;
    border: none;
    font-size: 1.1rem;
    cursor: pointer;
    list-style: none;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.6rem;
    border-radius: 0.5rem 0 0 0;
    font-size: var(--font-size-12);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-600);
    user-select: none;
    z-index: 2;
    transition: all ease 0.5s;
  
    &-active{
      color: var(--pure-white-color);
      transition: all ease 0.5s;
    }
  
    &-disabled {
      pointer-events: none;
    }
  }

  .indicator{
    position: absolute;
    height: 2.219rem;
    left: 0;
    width: 78px;
    z-index: 1;
    transition: all ease 0.5s;

    .indicator-container{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--primary-color);
        border-radius: 0.5rem 0 0 0;
    }
    .svg-container{
        position: absolute;
        right: -37px;
    }
  }
}

