.icon-button {
    border: none;
    overflow: hidden;
    padding: 8px;
    position: relative;
    cursor: pointer;
    // width: 40px;
    aspect-ratio: 1;
    background: transparent;
    border-radius: 50%;
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-16);
    line-height: 23px;
  }

  
  .icon-button > .ripple {
    width: 20px;
    height: 20px;
    position: absolute;
    background: rgb(235, 235, 235);
    display: block;
    content: "";
    border-radius: 50%;
    opacity: 0.8;
    animation: 0.9s ease 1 forwards ripple-effect;
  }
  
  
  @keyframes ripple-effect {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(10);
      opacity: 0.375;
    }
    100% {
      transform: scale(35);
      opacity: 0;
    }
  }
  
  .icon-button > .content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
  }
  