$dialog-width: (
  "xs": 444px,
  "sm": 600px,
  "md": 900px,
  "lg": 1200px,
  "xl": 1536px,
);

.dialog {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0.3rem;
  border: 0;
  padding: 0.7rem;
  background-color: var(--pure-white-color);
  overflow-y: hidden auto;

  @each $key, $value in $dialog-width {
    &-width-#{$key} {
      max-width: $value;
      width: calc(100% - 64px);
    }
  }
}

.dialog::-webkit-scrollbar {
  display: none;
}

.dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.35);
}

.dialog[open] {
  animation: showDialog 250ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes showDialog {
  from {
    opacity: 0;
    transform: translateY(min(100px, 5vh));
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.dialog[open].dialog-close {
  animation: hideDialog 150ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes hideDialog {
  from {
    opacity: 1;
    transform: translateY(0%);
  }
  to {
    opacity: 0;
    transform: translateY(min(100px, 5vh));
  }
}
