.toast-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;

  .toast-container {
    border: 2px solid transparent;
    background-color: var(--fern-color);
    border-radius: 0.2rem;
    max-width: 480px;
    width: 350px;
    position: relative;
    cursor: pointer;
    margin-top: 1rem;
    margin-right: 1rem;
    padding: 1rem;
    gap: 1rem;
    color: var(--pure-white-color);
    opacity: 0.9;
    transition: 0.3s ease;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    animation: slide-in 500ms ease, slide-out 500ms 3s;

      &-success{
        background-color: var(--fern-color);  
      }

      &-error{
        background-color: var(--crimson-color);  
      }

    .toast-button {
      float: right;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      background-color: var(--pure-white-color);
      outline: none;
      border: none;
      width: 30px;
      aspect-ratio: 1;
      cursor: pointer;
      color: var(--grey-color);
      font-size: var(--font-size-20);
      font-weight: var(--font-weight-600);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .toast-content{
      margin-block: auto;
    }
  }

  .toast-container:hover{
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    // transform: translateX(-50%);
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    // visibility: visible;
  }
  to {
    // transform: translateX(0%);
    opacity: 0.9;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-out {
  from {
    opacity: 0.9;
    transform: translate3d(0, 0, 0);
  }

  to {
    // visibility: hidden;
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
