:root {
  /* Color variables */

  /* App level primary color */
  --primary-color: #0097e9;
  --primary-hover-color: #51aee0;
  --primary-hover-dark-color: #0080c5;
  /* App level primary color */

  /* App level max-width */
  --primary-container-max-width: 1440px;
  /* App level max-width */

  --pastel-blue-color: #dff3ff;
  --off-white-color: #fafaf4;
  --pure-white-color: #ffffff;
  --jet-black-color: #212121;
  --soft-gray-color: #d4d4d4;
  --grey-color: #797979;
  --black-color: #0000003b;
  --orange-color: #ffc046;
  --content-border-color: #7aa0d1;
  --question-border-color: #e86850;
  --header-text-color: #b94e62;
  --edit-form-background-color: #f1f2f0;
  --assess-btn-color: #f0f0f0;
  --background-color: #f7f7f9;
  --grey-text-color: #333;
  --maroon-color: #b5322e;
  --light-blue-color: #c7e9ff;
  --periwinkle-blue-color: #97c6e3;
  --pastel-yellow-color: #f8e896;
  --pale-goldenrod-color: #dfd087;
  --lightgray-color: lightgray;
  --lightcoral-color: lightcoral;
  --light-pink: rgb(255, 183, 183);
  --green-color: green;
  --red-color: red;
  --gainsboro-color: #f7f7f7;
  --olive-drab-color: rgb(181, 181, 6);
  --pale-gray-color: #e6e6e6;
  --arylide-yellow-color: #faeb91;
  --grayish-white-color: #e1e1e1;
  --dodger-blue-color: #1b68ff;
  --off-white-color: #ffffffb3;
  --bluish-gray-color: #cdd4d8;
  --medium-gray-color: #6d6d6e;
  --light-grey-color: #d5d5d5;
  --light-gray-shade-color: #cdcdcd;
  --light-blue-gray-color: #e2e8f0;
  --light-grayish-blue-color: #f1f5fa;
  --pale-blue-color: #f1f5f9;
  --midnight-blue-color: #202a3c;
  --fern-color: #5cb85c;
  --crimson-color: #d9534f;
  --white-smoke-color: #f3f3f3;
  --chinese-silver-color: #ccc;
  --davys-gray-color: #525252;
  --platinum-color: #e5e5e5;
  --deep-blue-color: #0e26a3;
  --eerie-black-color: #181818;
  --pale-cyan-color: #b5ffed;
  --strong-cyan-color: #02bf90;
  --vivid-orange-color: #ffa009;
  --very-soft-orange-color: #fdeec7;
  --dark-red-color: #b11313;
  --semi-transparent-grayish-blue-color: rgba(160, 162, 179, 0.2);
  --semi-transparent-red-color: rgba(177, 19, 19, 0.15);
  --semi-transparent-white-color: rgba(255, 255, 255, 0.7);
  --semi-transparent-black-color: rgba(0, 0, 0, 0.05);
  --luxury-pearl-color: #f4f7fb;
  --alice-blue: rgba(245, 246, 248, 1);
  --caribbean-green: rgb(0, 227, 150);
  --slate-blue: rgb(119, 93, 208);
  --light-coral-pink: #fbdbd8;
  --light-sky-blue: #cbebff;

  --default-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1),
    0 2px 7px 0 rgba(0, 0, 0, 0.1);

  /* App background gradient */
  --light-sky-blue-color: #bee0ff;
  --light-gray-color: #f2f2f2;
  --app-background-gradient: linear-gradient(
    45deg,
    var(--light-gray-color) 65%,
    var(--light-sky-blue-color) 100%
  );

  /* linear gradients */
  --pastel-blue-purple-gradient: linear-gradient(
    90deg,
    #9ca7fb 0%,
    #85adf9 35%,
    #6fb3f7 100%
  );
  --pastel-pink-gradient: linear-gradient(
    90deg,
    #d17ccc 0%,
    #d47abe 35%,
    #dc779d 100%
  );
  --pastel-peach-orange-gradient: linear-gradient(
    90deg,
    #ff9c7b 0%,
    #ffa867 35%,
    #ffb64f 100%
  );
  --aqua-teal-cyan-gradient: linear-gradient(
    90deg,
    #7fd5ad 0%,
    #7fd5cc 35%,
    #81d6ec 100%
  );
  --pastel-deep-pink-gradient: linear-gradient(90deg, #fbaed2 0%, #fee1e8 100%);
  --cotton-candy-pink-gradient: linear-gradient(
    135deg,
    #9796f0 10%,
    #fbc7d4 100%
  );
  --lime-green-gradient: linear-gradient(135deg, #81fbb8 10%, #28c76f 100%);
  --coral-orange-violet-gradient: linear-gradient(
    135deg,
    #f1ca74 10%,
    #a64db6 100%
  );

  /* Font style variables */
  --font-style-normal: normal;

  /* Font size, font family and font weight variables */
  --default-font-family: Poppins, sans-serif;
  --font-family-lato: Lato;

  --font-size-10: 10px;
  --font-size-11: 11px;
  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-17: 17px;
  --font-size-18: 18px;
  --font-size-24: 24px;
  --font-size-28: 28px;
  --font-size-40: 40px;

  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
}
