.datepicker-container{
    position: relative;
    width: 100%;
    max-width: calc(100% - 3rem);
    
    .react-datepicker-wrapper{
      width: 100%;
    }
    
    .react-datepicker-popper{
      z-index: 100;
    }

    .datepicker{
        width: calc(100% - 1rem);
        height: 42px;
        font-size: var(--font-size-14);
        font-family: var(--default-font-family);
        color: var(--grey-color);
        padding: 0 0.5rem;
        transition: 0.3s ease-in;
        outline: none;
    
        // outlined input field
        &-outlined {
            border: 1px solid var(--bluish-gray-color);
            border-radius: 6px;
      
            &:focus {
              outline: none;
              border: 1px solid var(--primary-color);
            }
          }
      
          // standard input field
          &-standard {
            border: none;
            border-bottom: 1px solid var(--bluish-gray-color);
      
            &:focus {
              outline: none;
              border-bottom: 1px solid var(--primary-color);
            }
          }
      
          // input field when error - outlined
          &-error-outlined {
            border: 1px solid var(--red-color);
            border-radius: 6px;
          }
      
          // input field when error - standard
          &-error-standard {
            border-bottom: 1px solid var(--red-color);
          }

          &-disabled{
            background: var(--light-grayish-blue-color);
          }
    }

    .datepicker-clear-button{
        padding: 0 3rem;
    }

    .datepicker-clear-button::after{
        background-color: var(--primary-color);
        width: 1.2rem;
        height: 1.2rem;
        font-size: var(--font-size-16);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .datepicker-custom-icon {
        position: absolute;
        aspect-ratio: 1;
        top: 0;
        right: 0;
        height: 42px;
        text-align: center;
      }
}