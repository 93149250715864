$title-position: (
  center, flex-end, flex-start
);

.title-container {
  display: flex;
}

@each $key in $title-position{
    .title-justifyContent-#{$key}{
        justify-content: $key;
    }
}


.title {
  color: var(--midnight-blue-color);
  font-family: var(--default-font-family);
  font-size: var(--font-size-24);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-600);
  position: relative;
  width: fit-content;
  user-select: none;
}

.title-underline::after {
  content: "";
  position: absolute;
  width: 62px;
  height: 3px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--primary-color);
}
