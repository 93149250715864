.info-list-container,
.info-form-container {
  margin: 0.5rem;
  gap: 1rem;
}

.google-maps-dialog-container {
  width: 100%;
  height: 60vh;
  .map-container {
    height: 100%;
    width: 100%;
  }
}

.location-form-container {
  .google-location-input {
    width: calc(100% - 1rem);
    height: 42px;
    font-size: var(--font-size-14);
    font-family: var(--default-font-family);
    color: var(--grey-color);
    padding: 0 0.5rem;
    transition: 0.3s ease-in;
    outline: none;
    background: var(--pure-white-color);
    border: 1px solid var(--bluish-gray-color);
    border-radius: 6px;

    &:focus {
      outline: none;
      border: 1px solid var(--primary-color);
    }

    &-standard {
      border: none;
      border-bottom: 1px solid var(--bluish-gray-color);

      &:focus {
        outline: none;
        border-bottom: 1px solid var(--primary-color);
      }
    }
  }
}

.jobs-form {
  .estimated-cost-label-container {
    display: flex;
    // position: relative;

    // .estimated-cost-tooltip {
    //   position: absolute;
    //   top: 30%;
    //   left: 30%;
    //   transform: translate(-50%, -50%);
    // }
  }
}
