.project-summary-list-container, .project-summary-form-container{
    margin: 1.5rem;
    gap: 1rem;
}

.project-summary-location-container{
    gap: 2.0rem;

    .project-summary-location-detail{
        margin-bottom: 30px;
    }
    .paragraph-container{
        width: 100%;
        max-width: 350px;
        margin: auto;
    }
}