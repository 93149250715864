.campaigns-list-container , .campaigns-form-container{
    margin: 1.5rem;
    gap: 2rem;
}


.manual-assign-form-container{
    gap: 2.2rem;

    .employee-management-status-form{
        gap: 1.5rem;
    }
    .input-container{
        width: 100%;
        max-width: 350px;
        margin: auto;
    }
}