.document-management-list-container,
.document-management-form-container {
  margin: 1.5rem;
  gap: 1rem;

  .data-table-attachment-container {
    width: 50px;
    aspect-ratio: 1;
    min-width: 50px;
    border-radius: 0.2rem;
    overflow: hidden;
    margin: auto;

    .data-table-attachment {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
