.navigation-container {
  min-height: 100vh;
  height: 100%;
  width: 120px;
  padding-top: 1rem;
  border-left: 12px solid var(--pure-white-color);
  position: sticky;
  inset: 0;
  z-index: 100;
  padding-bottom: 40px;

  .logo-container {
    text-align: center;
    max-width: 100%;

    .logo-style {
      width: 60px;
    }
  }
}

.navigation-menu {
  width: 60px;

  .navigation-unordered-list {
    position: relative;
    text-align: center;

    .navigation-indicator {
      position: absolute;
      background-color: var(--pure-white-color);
      top: 0;
      height: 70px;
      width: 86px;
      right: -25%;
      border-radius: 0px 40px 40px 0px;
      transition: 0.5s;
      box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.05);
    }

    .navigation-indicator::before {
      content: "";
      position: absolute;
      width: 40px;
      aspect-ratio: 1;
      border-radius: 50%;
      top: -40px;
      left: 2px;
      box-shadow: -16px 18px var(--pure-white-color);
      background: transparent;
    }

    .navigation-indicator::after {
      content: "";
      position: absolute;
      width: 40px;
      aspect-ratio: 1;
      border-radius: 50%;
      bottom: -40px;
      left: 2px;
      box-shadow: -16px -18px var(--pure-white-color);
    }

    .navigation-item-list {
      position: relative;
      height: 4.5rem;
      aspect-ratio: 1;
      list-style: none;

      .navigation-item-link {
        position: relative;
        text-align: center;
        margin: auto;
        width: 100%;
        height: 100%;

        .navigation-icon {
          position: relative;
          text-align: center;
          font-size: 1.5rem;
          transition: 0.5s;
          z-index: 4;
        }
      }
    }

    @for $i from 0 to 20 {
      .navigation-item-list.active#{$i} .navigation-icon {
        transform: translateX(18px);
      }
    }

    @for $i from 0 to 20 {
      .navigation-item-list.active#{$i} ~ .navigation-indicator {
        transform: translateY(calc(4.5rem * #{$i}));
      }
    }
  }
}
