$card-width: (
  "xs":444px,
  "sm": 600px,
  "md": 900px,
  "lg": 1283px,
  "xl": 1440px
);

.card-container{
    width: 100%;
    min-height: 200px;
    margin-inline: auto;
    background-color: var(--pure-white-color);
    padding: 1rem;
    border-radius: 0.3rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.09);

    @each $key, $value in $card-width{
        &-width-#{$key}{
          max-width: $value;
        }
      }
}