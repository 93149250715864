.file-attachment-main-container {
  gap: 1rem;
  max-width: calc(100% - 3.5rem);

  .file-attachment-container {
    min-width: 100px;
    width: 100px;
    aspect-ratio: 1;
    background-color: var(--pure-white-color);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
    border-radius: 0.8rem;
    overflow: hidden;
    cursor: pointer;
  
    .file-attachment {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .file-name-and-actions-container{

    .file-name {
      width: 20ch;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--grey-color);
    }
  }
}

