.button {
  margin: 8px;
  padding: 12px 24px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border-radius: 6px;
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16);
  line-height: 23px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  > .content {
    position: relative;
    z-index: 2;
  }

}

.button-full{
  width: 100%;
  max-width: calc(100% - 3rem);
}

.button-disabled{
  opacity: 0.6;
  cursor: not-allowed;
}

.button-variant-standard{
  color: var(--pure-white-color);
  background: var(--primary-color);
  border: none;
}

.button-variant-outlined{
  color: var(--primary-color);
  background: var(--pure-white-color);
  border: 1px solid var(--primary-color);
}

.button > .ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  background: var(--primary-hover-color);
  display: block;
  content: "";
  border-radius: 50%;
  opacity: 1;
  animation: 0.9s ease 1 forwards ripple-effect;
}


@keyframes ripple-effect {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(10);
    opacity: 0.375;
  }
  100% {
    transform: scale(35);
    opacity: 0;
  }
}


